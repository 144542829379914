<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading
                        ><span class="red--text">妙</span>典イオン前店</heading
                    >
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >妙典イオン（フードコート出口）から徒歩20秒!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >千葉県市川市妙典5丁目5-14</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >なし</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img height="360px" :src="imageMv"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1616998741369!6m8!1m7!1s-N12rDM-I6aBK6knE-_Bmg!2m2!1d35.69210434709785!2d139.9287289483061!3f27.612535121510177!4f2.0527089249061277!5f0.7820865974627469"
                            width="100%"
                            height="360px"
                            style="border: 0"
                            allowfullscreen=""
                            loading="lazy"
                        ></iframe>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">妙典駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出て、真っ直ぐ高架線下を進み、突き当りまで行きます。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            イオン側(バスロータリー)に出ます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span
                                ><span class="red--text text-md-h6">3</span>.
                                イオンと高架線の間を進みます。</span
                            >
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            イオン1番館側に向かって信号を渡り、右に曲がります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">5</span>.
                            保険の窓口側へ交差点の信号を渡ります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">6</span>.
                            カエルの青い看板のある道を入り、クリーニング屋の黄色の看板がある建物になります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0">
                        <div class="navigation">
                            <span class="red--text text-md-h6">7</span>.
                            理容室ブレスさんの隣がREVOIST妙典イオン前店になります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessG"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの4号店です！
妙典駅から徒歩6分、妙典イオンから徒歩30秒のジムです！
妙典イオンの近くにあるため、日常の買い物ついでにパーソナルトレーニングに通えます！
現在、妙典にお住まいの多くのお客様に通っていただいております！
お客様起点のパ―ソナルトレーナーが、分かりやすい言葉で、丁寧に、真摯にあなたに向き合ってレッスンをします！
`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '妙典イオン前店',
            center: {
                lat: 35.69230521446847,
                lng: 139.92880714815678,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.69230521446847,
                        lng: 139.92880714815678,
                    },
                    title: '妙典イオン前店',
                },
            ],
            message: `パーソナルジム REVOISTの4号店です！<br />
妙典駅から徒歩6分、妙典イオンから徒歩30秒のジムです！<br />
妙典イオンの近くにあるため、日常の買い物ついでにパーソナルトレーニングに通えます！<br />
現在、妙典にお住まいの多くのお客様に通っていただいております！<br />
お客様起点のパ―ソナルトレーナーが、分かりやすい言葉で、丁寧に、真摯にあなたに向き合ってレッスンをします！<br /><br />
「トレーニング」＝「つらい」ではなく、楽しみながら続けら れるように。お客様ひとりひとりの体力に合わせてしっかりかつ丁寧にトレーニングできるようサポートいたします！<br />
妙典イオン前店トレーナー：平元翔太

`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '妙典イオン前店',
                    disabled: true,
                    href: 'access-4',
                },
            ]
        },

        imageMv() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_4/7-1@2x.png'
                : 'static/pc_access_4/7-1@2x.png'
        },
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_4/1.png'
                : 'static/sp_access_4/1.png'
        },
        accessB() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_4/2.png'
                : 'static/sp_access_4/2.png'
        },
        accessC() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_4/3.png'
                : 'static/sp_access_4/3.png'
        },
        accessD() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_4/4.png'
                : 'static/sp_access_4/4.png'
        },
        accessE() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_4/5.png'
                : 'static/sp_access_4/5.png'
        },
        accessF() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_4/6.png'
                : 'static/sp_access_4/6.png'
        },
        accessG() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_4/7.png'
                : 'static/sp_access_4/7.png'
        },
        accessH() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_4/1.png'
                : 'static/sp_access_2/IMG_1571.png'
        },

        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/MyodenAeon.jpg'
                : 'static/landing/MyodenAeon.jpg'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/妙典イオン前店　平元翔太トレーナー.jpg'
                : 'static/landing/trainer/妙典イオン前店　平元翔太トレーナー.jpg'
        },
    },
}
</script>

<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
